import React, { useState } from 'react';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import './ImageSlider.css';

export const ImageSlider = ({
  id = 'ImageSlider',
  images,
  name = 'Vehicle Name',
  description = 'Vehicle Description',
  bags,
  passengers,
  feature,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const showImage = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="container ImageSlider-container">
      <div className="row">
        <div className="col-lg-6">
          <div className="ImageSlider-wrapper">
            <div className="ImageSlider-main-box" key={id + 0}>
              <img
                className="ImageSlider-main-img"
                src={images[activeIndex]}
                alt={activeIndex + 1}
                id={id + 0}
              />
            </div>
            <div className="ImageSlider-sub-wrapper">
              {images.map((image, index) => (
                <div className="ImageSlider-sub-box" key={id + (index + 1)}>
                  <img
                    className={`ImageSlider-sub-img ${
                      index === activeIndex ? 'ImageSlider-active' : ''
                    }`}
                    src={image}
                    alt={index + 1}
                    onClick={() => showImage(index)}
                    id={id + (index + 1)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6 ImageSlider-info">
          <div>
            <h2>{name}</h2>
            <p>{description}</p>
            {bags || passengers ? <hr /> : null}
            <div>
              {passengers ? (
                <div>
                  <PersonOutlineOutlinedIcon /> {passengers}
                </div>
              ) : null}
            </div>
            <div>
              {bags ? (
                <div>
                  <LuggageOutlinedIcon /> {bags}
                </div>
              ) : null}
            </div>
            {feature ? <hr /> : null}
            <div>
              {feature ? (
                <div>
                  Feature: <span>{feature}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;

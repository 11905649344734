import React from 'react';
import { useTranslation } from 'react-i18next';
import { WelcomeBanner } from '../../components/WelcomeBanner';
import './Journey.css';

import PrivateJet from '../../assets/Image/Pick-Up-From-Private-Jet.jpg';
import JourneyFrontImg from '../../assets/Image/Distant-Road.jpg';

export const Journey = () => {
  const { t } = useTranslation('translation');

  return (
    <div>
      <WelcomeBanner
        imgSrc={JourneyFrontImg}
        mainMsg={
          <>
            {t('journey.our')}
            <span style={{ color: '#DDB989' }}> {t('journey.journey')}</span>
          </>
        }
      />
      <div className="container" style={{ padding: '50px 12px 30px 12px' }}>
        <hr className="Journey-divider" />

        <div className="row Journey-feature">
          <div className="col-md-7">
            <h2>{t('journey.tag1')}</h2>
            <p>{t('journey.tag1Desc')}</p>
          </div>
          <div className="col-md-5">
            <div className="Journey-feature-img">
              <img
                className="img-fluid"
                src={PrivateJet}
                alt="Platinum chauffeur picking up VIP from private jet"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journey;

import React from 'react';
import TextField from '@mui/material/TextField';

export const commonStyles = {
  width: '100%',
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    '& fieldset': {
      borderColor: '#D3D3D3',
    },
    '&:hover fieldset': {
      borderColor: '#2B2B2B',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2B2B2B',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#000000',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2B2B2B',
  },
};

export const CustomTextField = ({
  id = 'outlined',
  value = '',
  label = 'Text Field',
  data_testid = '',
  onValueChange = () => {},
  required = false,
  type = 'text',
  error = false,
  helperText = ' ',
  readOnly = false,
  autoComplete = 'on',
  multiline = false,
  rows = 1,
  disabled = false,
  inputProps = {},
}) => {
  return (
    <div style={{ paddingBottom: '5px', width: '100%' }}>
      <TextField
        value={value}
        id={id}
        label={label}
        data_testid={data_testid}
        onChange={onValueChange}
        required={required}
        error={error}
        helperText={helperText}
        inputProps={inputProps}
        type={type}
        autoComplete={autoComplete}
        multiline={multiline}
        rows={rows}
        sx={commonStyles}
        disabled={disabled}
        InputProps={{ readOnly }}
      />
    </div>
  );
};

export default CustomTextField;

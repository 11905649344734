import React from 'react';
import './WelcomeBanner.css';

export const WelcomeBanner = ({
  imgSrc,
  mainMsg,
  subMsg = '',
  animate = false,
  overlay = true,
}) => {
  return (
    <div
      className="container-fluid Wb-welcome"
      style={{
        backgroundImage: 'url(' + imgSrc + ')',
      }}
    >
      {overlay && <div className="Wb-overlay"></div>}
      <div className="container Wb-welcome-msg-box">
        <div className="Wb-welcome-msg">
          <div className={`Wb-welcome-mainmsg ${animate && 'Idx-up-animate'}`}>
            {mainMsg}
          </div>
          {subMsg && (
            <div className={`Wb-welcome-submsg ${animate && 'Idx-up-animate'}`}>
              {subMsg}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomeBanner;

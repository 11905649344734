import React, { useEffect, useRef, useState } from 'react';
import './Service.css';
import { useTranslation } from 'react-i18next';
import { WelcomeBanner } from '../../components/WelcomeBanner';

import ServiceFrontImg from '../../assets/Image/Neon-Hand-Shake.jpg';
import Airplane from '../../assets/Image/Airplane.jpg';
import ManInSuit from '../../assets/Image/Man-In-Suit.jpg';
import WeddingRings from '../../assets/Image/Wedding-Rings.jpg';
import Vip from '../../assets/Image/VIP.jpg';
import BirthdayBalloons from '../../assets/Image/Birthday-Balloons.jpg';

export const Service = () => {
  const { t } = useTranslation('translation');
  const elementsToAnimate = useRef([]);
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (elementsToAnimate.current[idx]) {
        const elementTop =
          elementsToAnimate.current[idx].getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;
        if (elementTop < viewportHeight * 0.95) {
          if (idx % 2 === 0) {
            elementsToAnimate.current[idx].classList.add('Idx-right-animate');
          } else {
            elementsToAnimate.current[idx].classList.add('Idx-left-animate');
          }
          setIdx((prevIdx) => prevIdx + 1);
          if (elementsToAnimate.current.length === idx + 1) {
            elementsToAnimate.current.array.forEach((element) => {
              element.classList.remove('Idx-to-animate');
              if (
                !element.classList.contains('Idx-right-animate') ||
                !element.classList.contains('Idx-left-animate')
              ) {
                if (idx % 2 === 0) {
                  elementsToAnimate.current[idx].classList.add(
                    'Idx-right-animate',
                  );
                } else {
                  elementsToAnimate.current[idx].classList.add(
                    'Idx-left-animate',
                  );
                }
              }
            });
            window.removeEventListener('scroll', handleScroll);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementsToAnimate, idx, setIdx]);

  return (
    <div>
      <WelcomeBanner
        imgSrc={ServiceFrontImg}
        mainMsg={
          <>
            {t('service.our')}
            <span style={{ color: '#DDB989' }}> {t('service.services')}</span>
          </>
        }
      />
      <div className="container" style={{ padding: '50px 12px 30px 12px' }}>
        <section
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
          id="Service-airport"
        >
          <hr className="Service-divider" />
          <div className="row Service-feature">
            <div className="col-md-7">
              <h2>{t('service.airport')}</h2> &nbsp;
              <p className="Service-line-height">{t('service.airportDesc')}</p>
              <ul>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.airportTag1')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.airportTag2')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.airportTag3')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.airportTag4')}
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <div
                className="Service-feature-img"
                style={{ backgroundImage: `url(${Airplane})` }}
              ></div>
            </div>
          </div>
        </section>

        <section
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
          id="Service-business"
        >
          <hr className="Service-divider" />
          <div className="row Service-feature">
            <div className="col-md-7 order-md-2">
              <h2>{t('service.business')}</h2>
              <p className="Service-line-height">{t('service.businessDesc')}</p>
              <ul>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.businessTag1')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.businessTag2')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.businessTag3')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.businessTag4')}
                </li>
              </ul>
            </div>
            <div className="col-md-5 order-md-1">
              <div
                className="Service-feature-img"
                style={{ backgroundImage: `url(${ManInSuit})` }}
              ></div>
            </div>
          </div>
        </section>

        <section
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
          id="Service-marriage"
        >
          <hr className="Service-divider" />
          <div className="row Service-feature">
            <div className="col-md-7">
              <h2>{t('service.wedding')}</h2>
              <p className="Service-line-height">{t('service.weddingDesc')}</p>
              <ul>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.weddingTag1')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.weddingTag2')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.weddingTag3')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.weddingTag4')}
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <div
                className="Service-feature-img"
                style={{ backgroundImage: `url(${WeddingRings})` }}
              ></div>
            </div>
          </div>
        </section>

        <section
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
          id="Service-vip"
        >
          <hr className="Service-divider" />
          <div className="row Service-feature">
            <div className="col-md-7 order-md-2">
              <h2>{t('service.vip')}</h2>
              <p className="Service-line-height">{t('service.vipDesc')}</p>
              <ul>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.vipTag1')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.vipTag2')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.vipTag3')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.vipTag4')}
                </li>
              </ul>
            </div>
            <div className="col-md-5 order-md-1">
              <div
                className="Service-feature-img"
                style={{ backgroundImage: `url(${Vip})` }}
              ></div>
            </div>
          </div>
        </section>

        <section
          ref={(element) => elementsToAnimate.current.push(element)}
          className="Idx-to-animate"
          id="Service-birthday"
        >
          <hr className="Service-divider" />
          <div className="row Service-feature">
            <div className="col-md-7">
              <h2>{t('service.birthday')}</h2>
              <p className="Service-line-height">{t('service.birthdayDesc')}</p>
              <ul>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.birthdayTag1')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.birthdayTag2')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.birthdayTag3')}
                </li>
                <li className="Service-margin-adder">
                  <span>
                    <i className="fa fa-star"></i>
                  </span>
                  {t('service.birthdayTag4')}
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <div
                className="Service-feature-img"
                style={{ backgroundImage: `url(${BirthdayBalloons})` }}
              ></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Service;

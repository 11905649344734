import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { StaticHeader, StickyHeader, Footer } from './components/Common';
import { ScrollToTop } from './components/ScrollToTop';
import { Main } from './pages/Main';
import { Service } from './pages/Service';
import { Vehicle } from './pages/Vehicle';
import { Faq } from './pages/Faq';
import { Journey } from './pages/Journey';
import { Terms } from './pages/Terms';
import { Gallery } from './pages/Gallery';
import { Reservation } from './pages/Reservation';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    borderRadius: '0px',
  },
  '&.notistack-MuiContent-error': {
    borderRadius: '0px',
  },
  '&.notistack-MuiContent-info': {
    borderRadius: '0px',
  },
  '&.notistack-MuiContent-warning': {
    borderRadius: '0px',
  },
}));

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <SnackbarProvider
          maxSnack={5}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
          }}
        >
          <StaticHeader />
          <StickyHeader />
          <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/Service" element={<Service />}></Route>
            <Route path="/Vehicle" element={<Vehicle />}></Route>
            <Route path="/Reservation" element={<Reservation />}></Route>
            <Route path="/About/Faq" element={<Faq />}></Route>
            <Route path="/About/Journey" element={<Journey />}></Route>
            <Route path="/About/Terms" element={<Terms />}></Route>
            <Route path="/About/Gallery" element={<Gallery />}></Route>
            <Route path="*" element={<Main />}></Route>
          </Routes>
          <Footer />
        </SnackbarProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { WelcomeBanner } from '../../components/WelcomeBanner';
import './Terms.css';

import TermFrontImg from '../../assets/Image/Exclamation-Mark-Hazard-Sign.jpg';

export const Terms = () => {
  const { t } = useTranslation('translation');

  const purchases = [];
  for (let i = 0; i < 6; i++) {
    purchases.push(
      <li key={'purchase' + i}>{t('term.purchase' + (i + 1))}</li>,
    );
  }

  const payment = [];
  for (let i = 0; i < 7; i++) {
    payment.push(<li key={'payment' + i}>{t('term.payment' + (i + 1))}</li>);
  }

  const cancel = [];
  for (let i = 0; i < 5; i++) {
    cancel.push(<li key={'cancel' + i}>{t('term.cancel' + (i + 1))}</li>);
  }

  const rules = [];
  for (let i = 0; i < 19; i++) {
    rules.push(<li key={'rules' + i}>{t('term.rules' + (i + 1))}</li>);
  }

  const limitation = [];
  for (let i = 0; i < 6; i++) {
    limitation.push(
      <li key={'limitation' + i}>{t('term.limitation' + (i + 1))}</li>,
    );
  }

  const security = [];
  for (let i = 0; i < 3; i++) {
    security.push(<li key={'security' + i}>{t('term.security' + (i + 1))}</li>);
  }

  const complaint = [];
  for (let i = 0; i < 2; i++) {
    complaint.push(
      <li key={'complaint' + i}>{t('term.complaint' + (i + 1))}</li>,
    );
  }

  return (
    <div className="Terms-decorator">
      <WelcomeBanner
        imgSrc={TermFrontImg}
        mainMsg={
          <>
            {t('term.termsAnd')}
            <span style={{ color: '#DDB989' }}> {t('term.conditions')}</span>
          </>
        }
      />
      <div className="container" style={{ padding: '50px 12px 30px 12px' }}>
        <hr className="Terms-divider" />
        <div className="row Terms-feature">
          <div className="col">
            <p>{t('term.desc')}</p>
            &nbsp;
            <h4>{t('term.purchase')}</h4>
            <ul>{purchases}</ul>
            &nbsp;
            <h4>{t('term.payment')}</h4>
            <ul>{payment}</ul>
            &nbsp;
            <h4>{t('term.cancel')}</h4>
            <ul>{cancel}</ul>
            &nbsp;
            <h4>{t('term.rules')}</h4>
            <p>{t('term.rulesDesc')}</p>
            <ul>{rules}</ul>
            &nbsp;
            <h4>{t('term.limitation')}</h4>
            <ul>{limitation}</ul>
            &nbsp;
            <h4>{t('term.security')}</h4>
            <ul>{security}</ul>
            &nbsp;
            <h4>{t('term.complaint')}</h4>
            <ul>{complaint}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;

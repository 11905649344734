import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './RectButton.css';

export const RectButton = ({
  children,
  onClick,
  variant = 'primary',
  loading = false,
}) => {
  return (
    <button
      className={`Rect-button ${
        variant === 'primary' ? 'Rect-primary-button' : 'Rect-secondary-button'
      }`}
      onClick={onClick}
      disabled={loading}
    >
      <div className="Rect-button-child-wrapper">
        <span className={loading ? 'Rect-button-children' : ''}>
          {children}
        </span>
        {loading && (
          <div className="Rect-button-progress">
            <CircularProgress
              sx={{ color: 'white' }}
              size={20}
              thickness={10}
            />
          </div>
        )}
      </div>
    </button>
  );
};

export default RectButton;

import React from 'react';
import './SquareButton.css';

export const SquareButton = ({ children, onClick }) => {
  return (
    <div className="Square-button" onClick={onClick}>
      {children}
    </div>
  );
};

export default SquareButton;

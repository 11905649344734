import React, { useState, useEffect, useCallback } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { TripPlanner } from '../TripPlanner';
import { LoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { CustomSelectField } from '../CustomSelectField';
import { CustomDateTimePicker } from '../CustomDateTimePicker';
import { CustomTextField } from '../CustomTextField';
import { SNACKVARIANT, initialFieldState } from '../../utilities/Constants';
import { BoxSelect } from '../BoxSelect';
import { RectButton } from '../Button';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import './CheckRate.css';

import suv from '../../assets/Vehicle/Cadillac-Escalade-1.png';
import sedan from '../../assets/Vehicle/Cadillac-Lyric.png';
import luxvan from '../../assets/Vehicle/Luxvan-1.png';

const libraries = ['places'];

export const CheckRate = () => {
  const { t } = useTranslation('translation');
  const { enqueueSnackbar } = useSnackbar();

  const handleAlert = useCallback(
    (message, variant) => {
      enqueueSnackbar(message, { variant });
    },
    [enqueueSnackbar],
  );

  const [addAPet, setAddAPet] = useState(false);
  const [carSelected, setCarSelected] = useState(null);
  const [distance, setDistance] = useState(0);
  const [hourCount, setHourCount] = useState(3);
  const [hourCountHelper, setHourCountHelper] = useState(t('reserve.hourKm3'));
  const [isPickUpdating, setIsPickUpdating] = useState(false);
  const [isReturnUpdating, setIsReturnUpdating] = useState(false);
  const [isRoundTrip, setIsRoundTrip] = useState('No');
  const [isSameDay, setIsSameDay] = useState(true);
  const [meetGreet, setMeetGreet] = useState(false);
  const [pickupDateTime, setPickupDateTime] = useState(initialFieldState);
  const [price, setPrice] = useState({});
  const [returnDateTime, setReturnDateTime] = useState(initialFieldState);
  const [returnDistance, setReturnDistance] = useState(0);
  const [returnStops, setReturnStops] = useState([]);
  const [stops, setStops] = useState([]);
  const [tripType, setTripType] = useState('Transfer');
  const [yulToOrigin, setYulToOrigin] = useState(0);

  const handleChange = (setter, value) => {
    setter({ ...initialFieldState, value });
  };

  const checkTime = (dateTime) => {
    if (dateTime) {
      const midnight = dateTime.startOf('day');
      const fourThirtyAM = midnight.clone().add(4, 'hours').add(30, 'minutes');
      return dateTime.isAfter(midnight) && dateTime.isBefore(fourThirtyAM);
    }

    return false;
  };

  const getRate = useCallback(
    (dist) => {
      let rate = 0;
      if (dist === 0) {
        rate = 0;
      } else if (dist > 0 && dist <= 150) {
        rate = -0.00244 * dist + 3.61571;
      } else if (dist > 150 && dist <= 200) {
        rate = -0.00244 * dist + 3.2281305;
      } else if (dist > 200 && dist <= 300) {
        rate = -0.00244 * dist + 3.049723;
      } else if (dist > 300 && dist <= 600) {
        rate = -0.00244 * dist + 3.854127;
      } else if (dist > 600 && dist <= 700) {
        rate = -0.00244 * dist + 4.049977;
      } else {
        rate = 2.672212;
      }

      if (carSelected === 'Suv') {
        return rate;
      } else if (carSelected === 'Sedan') {
        return rate - rate * 0.16;
      } else {
        if (dist > 150) {
          return rate + 1.55;
        } else {
          return rate * 1.6;
        }
      }
    },
    [carSelected],
  );

  useEffect(() => {
    if (isRoundTrip === 'Yes') {
      if (pickupDateTime.value && returnDateTime.value) {
        setIsSameDay(
          dayjs(pickupDateTime.value).isSame(
            dayjs(returnDateTime.value),
            'day',
          ),
        );
      } else {
        setIsSameDay(true);
      }
    } else {
      setIsSameDay(true);
    }
  }, [isRoundTrip, pickupDateTime.value, returnDateTime.value]);

  // UseEffect to calculate the price
  const calculateRate = useCallback(() => {
    if (!carSelected) {
      handleAlert(t('reserve.selectCarRequired'), SNACKVARIANT.WARNING);
      return;
    } else if (!pickupDateTime.value) {
      handleAlert(t('reserve.dateRequired'), SNACKVARIANT.WARNING);
      return;
    } else {
      const addressFound = stops.find((stop) => stop.value === '');
      if (addressFound) {
        handleAlert(t('trip.addressMissing'), SNACKVARIANT.WARNING);
        return;
      }
    }

    let temp = {};

    if (tripType === 'Transfer' || tripType === 'Airport Transfer') {
      let initRate = 0;
      let initFee = 0;

      if (carSelected === 'Suv') {
        initRate = 4.5;
        initFee = 50;
      } else if (carSelected === 'Sedan') {
        initRate = 3.5;
        initFee = 50;
      } else {
        initRate = 5.66667;
        initFee = 130;
      }

      if (stops.length > 1) {
        temp.basePrice = 0;
        const stopDistRate = getRate(distance);
        temp.basePrice += 30 * initRate;
        temp.basePrice += initFee;

        if (distance > 30) {
          temp.distanceFee = (distance - 30) * stopDistRate;
        } else {
          temp.stopFee = (stops.length - 2) * 50;
        }
      }

      if (returnStops.length > 1) {
        temp.returnBasePrice = 0;
        const returnStopDistRate = getRate(returnDistance);
        temp.returnBasePrice += 30 * initRate;
        temp.returnBasePrice += initFee;

        if (returnDistance > 30) {
          temp.returnDistanceFee = (returnDistance - 30) * returnStopDistRate;
        } else {
          temp.returnStopFee = (returnStops.length - 2) * 50;
        }
      }
    } else {
      const hourlyDistRate = getRate(yulToOrigin);
      let hourlyPrice = 0;

      if (hourCount >= 3 && hourCount < 6 && yulToOrigin > 35) {
        temp.tripToOrigin = (yulToOrigin - 35) * hourlyDistRate;
      } else if (hourCount >= 6 && hourCount < 10 && yulToOrigin > 100) {
        temp.tripToOrigin = (yulToOrigin - 100) * hourlyDistRate;
      } else if (hourCount >= 10 && yulToOrigin > 200) {
        temp.tripToOrigin = (yulToOrigin - 200) * hourlyDistRate;
      }

      if (carSelected === 'Suv') {
        if (hourCount > 3 && hourCount <= 6) {
          hourlyPrice = 140;
        } else if (hourCount > 6) {
          hourlyPrice = 130;
        } else {
          hourlyPrice = 150;
        }
      } else if (carSelected === 'Sedan') {
        hourCount < 8 ? (hourlyPrice = 120) : (hourlyPrice = 100);
      } else {
        if (hourCount > 3 && hourCount <= 6) {
          hourlyPrice = 195;
        } else if (hourCount > 6) {
          hourlyPrice = 175;
        } else {
          hourlyPrice = 225;
        }
      }

      temp.hourlyPrice = hourCount * hourlyPrice;
    }

    let preTaxTotal = 0;
    for (let key in temp) {
      preTaxTotal += temp[key];
    }

    temp.gst = preTaxTotal * 0.05;
    temp.qst = preTaxTotal * 0.09975;

    const pickUpOvernight = pickupDateTime.value
      ? checkTime(pickupDateTime.value)
      : false;
    const returnOvernight = returnDateTime.value
      ? checkTime(returnDateTime.value)
      : false;

    if (pickUpOvernight || returnOvernight) {
      temp.overnight = 0;

      if (pickUpOvernight) {
        temp.overnight += 50;
      }

      if (returnOvernight) {
        temp.overnight += 50;
      }
    }

    if (
      stops[0]?.value?.toLowerCase().includes('yul') ||
      stops[0]?.value?.includes('Bd Roméo Vachon Nord (Arrivées)')
    ) {
      temp.airportFee = 15;
    }

    if (
      returnStops[0]?.value?.toLowerCase().includes('yul') ||
      returnStops[0]?.value?.includes('Bd Roméo Vachon Nord (Arrivées)')
    ) {
      temp.returnAirportFee = 15;
    }

    if (addAPet) {
      temp.petFee = 50;
    }

    if (meetGreet) {
      temp.meetGreetFee = 25;
    }

    let total = 0;
    for (let key in temp) {
      total += temp[key];
    }
    temp.total = total;

    setPrice(temp);
  }, [
    carSelected,
    distance,
    returnDistance,
    stops,
    returnStops,
    hourCount,
    tripType,
    pickupDateTime.value,
    returnDateTime.value,
    yulToOrigin,
    getRate,
    addAPet,
    meetGreet,
    handleAlert,
    t,
  ]);

  useEffect(() => {
    setPrice({});
  }, [
    carSelected,
    distance,
    returnDistance,
    stops,
    returnStops,
    hourCount,
    tripType,
    pickupDateTime.value,
    returnDateTime.value,
    yulToOrigin,
    getRate,
    addAPet,
    meetGreet,
  ]);

  const navigate = useNavigate();

  const handleReserveClick = () => {
    navigate('/Reservation');
  };

  return (
    <>
      <div>
        <div className="Rate-up-select">
          <div
            onClick={() => setTripType('Transfer')}
            style={
              tripType === 'Transfer' ? { backgroundColor: '#e9dac7' } : {}
            }
          >
            {t('reserve.transfer')}
          </div>
          <div
            onClick={() => setTripType('Hourly')}
            style={tripType === 'Hourly' ? { backgroundColor: '#e9dac7' } : {}}
          >
            {t('reserve.hourly')}
          </div>
        </div>
        <div className="Rate-bot-box">
          {tripType !== 'Hourly' && (
            <div className="Reserve-padder">
              <CustomSelectField
                label={t('reserve.roundTrip')}
                options={[
                  {
                    key: t('reserve.yes'),
                    value: 'Yes',
                  },
                  { key: t('reserve.no'), value: 'No' },
                ]}
                value={isRoundTrip}
                onValueChange={(e) => {
                  if (e === 'No') {
                    setReturnStops([]);
                    setReturnDateTime(initialFieldState);
                    setReturnDistance(0);
                  }
                  setIsRoundTrip(e);
                }}
              />
              <CustomDateTimePicker
                label={t('reserve.date')}
                value={pickupDateTime.value}
                error={pickupDateTime.error}
                helperText={pickupDateTime.helperText}
                onValueChange={(e) => handleChange(setPickupDateTime, e)}
              />
            </div>
          )}
          <div className="Reserve-padder">
            {tripType === 'Hourly' ? (
              <div>
                <CustomTextField
                  type="number"
                  label={t('reserve.hours')}
                  inputProps={{
                    min: 3,
                    max: 24,
                  }}
                  value={hourCount}
                  onValueChange={(e) => {
                    if (e.target.value < 3) {
                      setHourCount(3);
                      handleAlert(
                        t('reserve.minHour', { min: 3 }),
                        SNACKVARIANT.WARNING,
                      );
                    } else if (e.target.value > 24) {
                      handleAlert(t('reserve.maxHour'), SNACKVARIANT.WARNING);
                      setHourCount(24);
                    } else {
                      if (e.target.value < 6) {
                        setHourCountHelper(t('reserve.hourKm3'));
                      } else if (e.target.value >= 6 && e.target.value < 10) {
                        setHourCountHelper(t('reserve.hourKm6'));
                      } else {
                        setHourCountHelper(t('reserve.hourKm10'));
                      }
                      setHourCount(e.target.value);
                    }
                  }}
                  helperText={hourCountHelper}
                  required={true}
                />
              </div>
            ) : isRoundTrip === 'Yes' ? (
              <div>
                <CustomDateTimePicker
                  label={t('reserve.returnDateTime')}
                  value={returnDateTime.value}
                  error={returnDateTime.error}
                  helperText={returnDateTime.helperText}
                  onValueChange={(e) => handleChange(setReturnDateTime, e)}
                />
              </div>
            ) : null}
            {tripType === 'Hourly' && (
              <CustomDateTimePicker
                label={t('reserve.date')}
                value={pickupDateTime.value}
                error={pickupDateTime.error}
                helperText={pickupDateTime.helperText}
                onValueChange={(e) => handleChange(setPickupDateTime, e)}
              />
            )}
          </div>
          <div className="Rate-special-checks">
            <div>
              <FormControlLabel
                control={<Checkbox onClick={() => setAddAPet(!addAPet)} />}
                label={t('reserve.addAPet')}
              />
            </div>
            {tripType !== 'Hourly' && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox onClick={() => setMeetGreet(!meetGreet)} />
                  }
                  label={t('reserve.meetGreet')}
                />
              </div>
            )}
          </div>
          <div className="Rate-box-select">
            <BoxSelect
              options={[
                { image: sedan, title: 'Sedan', passengers: '3', bags: '3' },
                { image: suv, title: 'Suv', passengers: '6', bags: '6' },
                {
                  image: luxvan,
                  title: t('reserve.luxuryVan'),
                  passengers: '11',
                  bags: '11',
                },
              ]}
              onSelect={(e) => {
                setCarSelected(e);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="Res-divider" />
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
        libraries={libraries}
      >
        <TripPlanner
          tripType={tripType}
          roundTrip={isSameDay && isRoundTrip === 'Yes'}
          stops={stops}
          setStops={setStops}
          setDistance={setDistance}
          setYulToOrigin={setYulToOrigin}
          title={
            isRoundTrip === 'Yes' && !isSameDay
              ? t('reserve.initialTripPlanner')
              : t('reserve.tripPlanner')
          }
          setUpdating={setIsPickUpdating}
        />

        {!isSameDay && isRoundTrip === 'Yes' && (
          <>
            <hr className="Res-divider" />
            <TripPlanner
              tripType={'Transfer'}
              roundTrip={false}
              stops={returnStops}
              setStops={setReturnStops}
              setDistance={setReturnDistance}
              setYulToOrigin={setYulToOrigin}
              title={t('reserve.returnTripPlanner')}
              setUpdating={setIsReturnUpdating}
            />
          </>
        )}
      </LoadScript>
      <div className="Rate-calc-box">
        {carSelected && (
          <div className="Rate-price-box">
            <h5>{t('reserve.priceEstimate')}</h5>

            {price.basePrice > 0 && (
              <p>
                <span>{t('reserve.basePrice') + ': '}</span>
                <span>{'$' + price.basePrice.toFixed(2)}</span>
              </p>
            )}
            {price.distanceFee > 0 && (
              <p>
                <span>{t('reserve.distanceFee') + ': '}</span>
                <span>{'$' + price.distanceFee.toFixed(2)}</span>
              </p>
            )}
            {price.stopFee > 0 && (
              <p>
                <span>{t('reserve.stopFee') + ': '}</span>
                <span>{'$' + price.stopFee.toFixed(2)}</span>
              </p>
            )}
            {price.returnBasePrice > 0 && (
              <p>
                <span>{t('reserve.returnBasePrice') + ': '}</span>
                <span>{'$' + price.returnBasePrice.toFixed(2)}</span>
              </p>
            )}
            {price.returnStopFee > 0 && (
              <p>
                <span>{t('reserve.stopFee') + ': '}</span>
                <span>{'$' + price.returnStopFee.toFixed(2)}</span>
              </p>
            )}
            {price.returnDistanceFee > 0 && (
              <p>
                <span>{t('reserve.returnDistanceFee') + ': '}</span>
                <span>{'$' + price.returnDistanceFee.toFixed(2)}</span>
              </p>
            )}
            {price.tripToOrigin > 0 && (
              <p>
                <span>{t('reserve.tripToOrigin') + ': '}</span>
                <span>{'$' + price.tripToOrigin.toFixed(2)}</span>
              </p>
            )}
            {price.hourlyPrice > 0 && (
              <p>
                <span>{t('reserve.hourlyPrice') + ': '}</span>
                <span>{'$' + price.hourlyPrice.toFixed(2)}</span>
              </p>
            )}
            {price.gst > 0 && (
              <p>
                <span>{t('reserve.gst') + ': '}</span>
                <span>{'$' + price.gst.toFixed(2)}</span>
              </p>
            )}
            {price.qst > 0 && (
              <p>
                <span>{t('reserve.qst') + ': '}</span>
                <span>{'$' + price.qst.toFixed(2)}</span>
              </p>
            )}
            {price.overnight > 0 && (
              <p>
                <span>{t('reserve.overnightCharge') + ': '}</span>
                <span>{'$' + price.overnight.toFixed(2)}</span>
              </p>
            )}
            {price.airportFee > 0 && (
              <p>
                <span>{t('reserve.airportFee') + ': '}</span>
                <span>{'$' + price.airportFee.toFixed(2)}</span>
              </p>
            )}
            {price.returnAirportFee > 0 && (
              <p>
                <span>{t('reserve.returnAirportFee') + ': '}</span>
                <span>{'$' + price.returnAirportFee.toFixed(2)}</span>
              </p>
            )}
            {price.petFee > 0 && (
              <p>
                <span>{t('reserve.petFee') + ': '}</span>
                <span>{'$' + price.petFee.toFixed(2)}</span>
              </p>
            )}
            {price.meetGreetFee > 0 && (
              <p>
                <span>{t('reserve.meetGreetFee') + ': '}</span>
                <span>{'$' + price.meetGreetFee.toFixed(2)}</span>
              </p>
            )}
            {price.total > 0 && (
              <p className="Res-total-price">
                <span>{t('reserve.total') + ': '}</span>
                <span>{'$' + price.total.toFixed(2)}</span>
              </p>
            )}
          </div>
        )}

        <div className="Rate-btn-box">
          {price.total > 0 && (
            <RectButton onClick={handleReserveClick}>
              {t('reserve.reserveLow')}
            </RectButton>
          )}
          <RectButton
            loading={isPickUpdating || isReturnUpdating}
            onClick={calculateRate}
          >
            {t('main.calculate')}
          </RectButton>
        </div>
      </div>
    </>
  );
};

export default CheckRate;

import React from 'react';
import './Header.css';
import logo from '../../assets/Brand/Platinum-Chauffeur-Logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { RectButton } from '../Button';
import { useTranslation } from 'react-i18next';

export const StickyHeader = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation('translation');

  const changeLanguage = () => {
    if (i18n.language === 'fr') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('fr');
    }
  };

  const handleMenuItemClick = () => {
    if (window.innerWidth < 992) {
      document.getElementById('navbar-toggler-btn').click();
    }
  };

  return (
    <nav className="navbar navbar-expand-lg sticky-top Nav-bar">
      <div className="container-fluid" style={{ padding: '0px 30px' }}>
        <div className="navbar-brand">
          <Link to="/">
            <img src={logo} height="50" alt="Platinum Chauffuer Logo" />
          </Link>
        </div>
        <div className="Nav-btn-container">
          <button
            className="Nav-lang-btn Nav-disp-1"
            onClick={() => changeLanguage()}
          >
            {i18n.language === 'fr' ? 'EN' : 'FR'}
          </button>
          <button
            id="navbar-toggler-btn"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            style={{
              borderColor: 'rgb(180, 180, 180)',
              color: '#fff',
              padding: '5px 12px',
              fontSize: '30px',
            }}
          >
            <span className="fa fa-bars"></span>
          </button>
        </div>
        <div
          className={`collapse navbar-collapse Nav-bar-menu`}
          id="collapsibleNavbar"
        >
          <ul className="navbar-nav">
            <li className="nav-item" onClick={handleMenuItemClick}>
              <Link className="nav-link" to="/">
                {t('navbar.home')}
              </Link>
            </li>
            <li className="nav-item" onClick={handleMenuItemClick}>
              <Link className="nav-link" to="/Service">
                {t('navbar.services')}
              </Link>
            </li>
            <li className="nav-item" onClick={handleMenuItemClick}>
              <Link className="nav-link" to="Vehicle">
                {t('navbar.vehicles')}
              </Link>
            </li>
            <li className="nav-item dropdown">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="nav-link dropdown-toggle"
                href=""
                role="button"
                data-bs-toggle="dropdown"
              >
                {t('navbar.about')}
              </a>
              <ul className="dropdown-menu">
                <li onClick={handleMenuItemClick}>
                  <Link className="dropdown-item" to={'/About/Journey'}>
                    {t('navbar.ourJourney')}
                  </Link>
                </li>
                <li onClick={handleMenuItemClick}>
                  <Link className="dropdown-item" to={'/About/Gallery'}>
                    {t('navbar.ourGallery')}
                  </Link>
                </li>
                <li onClick={handleMenuItemClick}>
                  <Link className="dropdown-item" to={'/About/Terms'}>
                    {t('navbar.terms')}
                  </Link>
                </li>
                <li onClick={handleMenuItemClick}>
                  <Link className="dropdown-item" to={'/About/Faq'}>
                    {t('navbar.faq')}
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="nav-item Nav-reserve-menu-btn"
              onClick={handleMenuItemClick}
            >
              <RectButton
                onClick={() => {
                  navigate('/Reservation');
                }}
              >
                {t('navbar.reserveNow')}
              </RectButton>
            </li>
          </ul>
        </div>
        <div className="Nav-right-box Nav-disp-2">
          <button className="Nav-lang-btn" onClick={() => changeLanguage()}>
            {i18n.language === 'fr' ? 'EN' : 'FR'}
          </button>
          <div>
            <RectButton
              onClick={() => {
                navigate('/Reservation');
              }}
            >
              {t('navbar.reserveNow')}
            </RectButton>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default StickyHeader;

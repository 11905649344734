import React, { useMemo } from 'react';
import './Footer.css';
import brand from '../../assets/Brand/Platinum-Chauffeur-Logo-Brand.png';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { RectButton, SquareButton } from '../Button';
import { apiFetch } from '../../utilities/ApiFetch';

export const Footer = () => {
  const { t } = useTranslation('translation');

  const navigate = useNavigate();

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <footer id="footer-id">
      <div className="container-fluid" style={{ padding: '0px' }}>
        <div className="container Foot-up-box">
          <div className="row">
            <div className="col-12 col-md-12 col-xxl-3 Foot-item Foot-item-box-1">
              <div style={{ paddingBottom: '30px', textAlign: 'center' }}>
                <Link to="/">
                  <img
                    className="img-fluid"
                    src={brand}
                    alt="Platinum Chauffeur Logo And Brand"
                    style={{ maxHeight: '180px' }}
                  />
                </Link>
              </div>
              <div style={{ textAlign: 'center' }}>
                <RectButton
                  onClick={() => {
                    navigate('/Reservation');
                  }}
                >
                  <div>{t('footer.rideWithUs')}</div>
                </RectButton>
              </div>
            </div>

            <hr className="Foot-border-up" />

            <div className="col-12 col-sm-6 col-xxl-2 Foot-item">
              <div className="Foot-title-box">{t('footer.services')}</div>
              <div className="Foot-menu-box">
                <ul>
                  <li>
                    <HashLink to={'/Service#Service-airport'}>
                      {t('footer.airport')}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Service#Service-business'}>
                      {t('footer.business')}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Service#Service-marriage'}>
                      {t('footer.wedding')}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Service#Service-vip'}>
                      {t('footer.vip')}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Service#Service-birthday'}>
                      {t('footer.birthday')}
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Service#Service-birthday'}>
                      {t('footer.special')}
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>

            <hr className="Foot-border-down" />

            <div className="col-12 col-sm-6 col-xxl-2 Foot-item">
              <div className="Foot-title-box">{t('footer.vehicles')}</div>
              <div className="Foot-menu-box">
                <ul>
                  <li>
                    <HashLink to={'/Vehicle#suv'}>SUV</HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Vehicle#sedan'}>Sedan</HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Vehicle#limousine'}>Limousine</HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Vehicle#van'}>Sprinter Van</HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Vehicle#mini-coach'}>Mini Coach</HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Vehicle#coach-bus'}>Coach Bus</HashLink>
                  </li>
                  <li>
                    <HashLink to={'/Vehicle#motorcoach'}>Motorcoach</HashLink>
                  </li>
                </ul>
              </div>
            </div>

            <hr className="Foot-border-up" />

            <div className="col-12 col-sm-6 col-xxl-2 Foot-item">
              <div className="Foot-title-box">{t('footer.about')}</div>
              <div className="Foot-menu-box">
                <ul>
                  <li>
                    <Link to={'/About/Journey'}>{t('footer.ourJourney')}</Link>
                  </li>
                  <li>
                    <Link to={'/About/Gallery'}>{t('footer.ourGallery')}</Link>
                  </li>
                  <li>
                    <Link to={'/About/Terms'}>{t('footer.terms')}</Link>
                  </li>
                  <li>
                    <Link to={'/About/Faq'}>{t('footer.faq')}</Link>
                  </li>
                </ul>
              </div>
            </div>

            <hr className="Foot-border-down" />

            <div className="col-12 col-sm-6 col-xxl-3 Foot-item">
              <div className="Foot-title-box">{t('footer.contactUs')}</div>
              <div className="Foot-menu-box">
                <ul>
                  <li>{t('footer.phone')}: 514-975-3134</li>
                  <li>{t('footer.email')}: info@platinumchauffeur.ca</li>
                  <li
                    className="Foot-social-icons"
                    style={{
                      height: '50px',
                      paddingTop: '0px',
                      marginTop: '10px',
                    }}
                  >
                    <a href="https://www.instagram.com/platinum_chauffeurmtl/">
                      <SquareButton>
                        <i className="fa-brands fa-instagram Foot-icon"></i>
                      </SquareButton>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100085095223427">
                      <SquareButton>
                        <i className="fa-brands fa-facebook Foot-icon"></i>
                      </SquareButton>
                    </a>
                    <a href="https://www.tiktok.com/@platinumchauffeur">
                      <SquareButton>
                        <i className="fa-brands fa-tiktok Foot-icon"></i>
                      </SquareButton>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid Foot-low-box">
          {currentYear} &copy; Platinum Chauffeur | {t('footer.allRights')}
          <button
            style={{ opacity: '0', cursor: 'default' }}
            onClick={async () => {
              const response = await apiFetch('get', '/test');
              alert(response.reason || 'Backend Not Working');
            }}
          >
            Test
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

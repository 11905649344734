export const SNACKVARIANT = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

export const initialFieldState = {
  value: '',
  error: false,
  helperText: ' ',
};

import axios from 'axios';

export const apiFetch = async (method, url, data = null, file = null) => {
  try {
    let axiosConfig = {
      method,
      url: process.env.REACT_APP_API_URL + url,
    };

    if (method.toLowerCase() === 'post' && file) {
      const formData = new FormData();
      formData.append('file', file);

      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      axiosConfig = {
        ...axiosConfig,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    } else {
      axiosConfig = {
        ...axiosConfig,
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }

    const response = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    console.error('API Request Error:', error);
    throw error;
  }
};

export default apiFetch;

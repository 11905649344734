import React from 'react';
import './Header.css';
import brand from '../../assets/Brand/Platinum-Chauffeur-Logo-Brand.png';
import { Link } from 'react-router-dom';

export const StaticHeader = () => {
  return (
    <div className="container-fluid Nav-big-logo-box">
      <Link to="/Home">
        <img src={brand} height="180" alt="Platinum Chauffeur Logo And Brand" />
      </Link>
    </div>
  );
};

export default StaticHeader;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { WelcomeBanner } from '../../components/WelcomeBanner';
import './Faq.css';

import FaqFrontImg from '../../assets/Image/Red-Neon-Question-Mark.jpg';

export const Faq = () => {
  const { t } = useTranslation('translation');

  return (
    <div>
      <WelcomeBanner
        imgSrc={FaqFrontImg}
        mainMsg={
          <>
            {t('faq.frequently')}
            <span style={{ color: '#DDB989' }}> {t('faq.questions')}</span>
          </>
        }
      />
      <div className="container" style={{ padding: '50px 12px 30px 12px' }}>
        <hr className="Faq-divider" />
        <div className="row Faq-feature">
          <div className="col">
            <h2>
              <span style={{ color: '#DDB989' }}>{t('faq.question')}: </span>
              {t('faq.q1')}
            </h2>
            <p>{t('faq.a1')}</p>
          </div>
        </div>
        <hr className="Faq-divider" />
        <div className="row Faq-feature">
          <div className="col">
            <h2>
              <span style={{ color: '#DDB989' }}>{t('faq.question')}: </span>
              {t('faq.q2')}
            </h2>
            <p>{t('faq.a2')}</p>
          </div>
        </div>
        <hr className="Faq-divider" />
        <div className="row Faq-feature">
          <div className="col">
            <h2>
              <span style={{ color: '#DDB989' }}>{t('faq.question')}: </span>
              {t('faq.q3')}
            </h2>
            <p>{t('faq.a3')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;

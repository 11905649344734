import React, { useEffect, useRef, useState } from 'react';
import './Main.css';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { RectButton, SquareButton } from '../../components/Button';
import { useTranslation } from 'react-i18next';

import CadillacEscaladeDriverSeat from '../../assets/Image/Cadillac-Escalade-Driver-Seat.JPG';
import MontrealDowntownView from '../../assets/Image/Montreal-Downtown-View.jpg';
import PlaneInAirport from '../../assets/Image/Plane-In-Airport.jpg';
import PokerPlayingCards from '../../assets/Image/Poker-Playing-Cards.jpg';
import CityViewFromHighRise from '../../assets/Image/City-View-From-High-Rise.jpg';
import Airplane from '../../assets/Image/Airplane.jpg';
import ManInSuit from '../../assets/Image/Man-In-Suit.jpg';
import WeddingRings from '../../assets/Image/Wedding-Rings.jpg';
import Vip from '../../assets/Image/VIP.jpg';
import BirthdayBalloons from '../../assets/Image/Birthday-Balloons.jpg';
import NeonQuestionmark from '../../assets/Image/Neon-Questionmark.jpg';
import MapsVector from '../../assets/Image/Maps-Pointer-Vector.png';

import vid1080 from '../../assets/Video/1080.mp4';
import vid1080Phone from '../../assets/Video/vid1080Phone.mp4';

import { CustomModal } from '../../components/CustomModal';
import { CheckRate } from '../../components/CheckRate';

export const Main = () => {
  const { t } = useTranslation('translation');
  const elementsToAnimate = useRef([]);
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (elementsToAnimate.current[idx]) {
        const elementTop =
          elementsToAnimate.current[idx].getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;
        if (elementTop < viewportHeight * 0.95) {
          elementsToAnimate.current[idx].classList.add('Idx-up-animate');
          setIdx((prevIdx) => prevIdx + 1);
          if (elementsToAnimate.current.length === idx + 1) {
            window.removeEventListener('scroll', handleScroll);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementsToAnimate, idx, setIdx]);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const handleOpenViewModal = () => {
    setIsViewModalOpen(true);
  };

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false);
  };

  return (
    <>
      <div>
        <video
          autoPlay
          playsInline
          loop
          muted
          style={{
            width: '100%',
            height: 'calc(100vh - 76px)',
            objectFit: 'cover',
          }}
        >
          <source
            src={window.innerWidth > 768 ? vid1080 : vid1080Phone}
            type="video/mp4"
          ></source>
          Your browser does not support the video tag.
        </video>
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="container Idx-to-animate"
        >
          <div className="row Home-fact">
            <div className="col-lg-6 Home-fact-text">
              <h1>
                {t('main.hereForYour')}
                <span style={{ color: '#DDB989' }}> {t('main.needs')}</span>
              </h1>
              &nbsp;
              <p>{t('main.needsDesc')}</p>
              <h5 style={{ marginTop: '20px' }}>
                Montreal{' '}
                <span style={{ color: '#DDB989' }}>
                  <i className="fa fa-star"></i>
                </span>{' '}
                Quebec{' '}
                <span style={{ color: '#DDB989' }}>
                  <i className="fa fa-star"></i>
                </span>{' '}
                Ottawa{' '}
                <span style={{ color: '#DDB989' }}>
                  <i className="fa fa-star"></i>
                </span>{' '}
                Toronto
              </h5>
            </div>
            <div className="col-lg-6 Home-fact-img">
              <img
                className="img-fluid Home-img-fluid"
                src={CadillacEscaladeDriverSeat}
                alt="Cadillac Escalade Driver Seat"
              />
            </div>
          </div>
        </div>
        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="container-fluide Idx-to-animate Home-rate-m"
        >
          <div className="container Home-rate-c">
            <div className="Home-rate-wrap">
              <div className="Home-rate-img-box">
                <img
                  className="Home-rate-img"
                  src={MapsVector}
                  alt="Maps Vector PNG"
                />
              </div>
              <div className="Home-rate-text">
                <h1>
                  <span style={{ color: '#FFFFFF' }}>
                    {' '}
                    {t('main.checkOur')}
                  </span>{' '}
                  {t('main.rates')}
                </h1>
                <p>{t('main.ratesDesc')}</p>
                <RectButton onClick={handleOpenViewModal} variant="secondary">
                  <div>{t('main.checkRates')}</div>
                </RectButton>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item Home-slider-item active">
              <img
                src={MontrealDowntownView}
                alt="Montreal Mont Royal Downtown View"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <div className="container">
                <div className="carousel-caption">
                  <h1>{t('main.needToGo')}</h1>
                  <p className="Home-slider-info">{t('main.needToGoDesc')}</p>
                  <p>
                    <Link to="/Service">
                      <RectButton>
                        <div>{t('main.learnMore')}</div>
                      </RectButton>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item Home-slider-item">
              <img
                src={PlaneInAirport}
                alt="Plane seen from inside the airport"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <div className="container">
                <div className="carousel-caption">
                  <h1>{t('main.airTravel')}</h1>
                  <p className="Home-slider-info">{t('main.airTravelDesc')}</p>
                  <p>
                    <HashLink to={'/Service#Service-airport'}>
                      <RectButton>
                        <div>{t('main.learnMore')}</div>
                      </RectButton>
                    </HashLink>
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item Home-slider-item">
              <img
                src={PokerPlayingCards}
                alt="Playing cards on a poker table"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <div className="container">
                <div className="carousel-caption">
                  <h1>{t('main.gameNight')}</h1>
                  <p className="Home-slider-info">{t('main.gameNightDesc')}</p>
                  <p>
                    <HashLink to={'/Service#Service-birthday'}>
                      <RectButton>
                        <div>{t('main.learnMore')}</div>
                      </RectButton>
                    </HashLink>
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item Home-slider-item">
              <img
                src={CityViewFromHighRise}
                alt="City downtown view from a high rise building"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <div className="container">
                <div className="carousel-caption">
                  <h1>{t('main.haveBusiness')}</h1>
                  <p className="Home-slider-info">
                    {t('main.haveBusinessDesc')}
                  </p>
                  <p>
                    <HashLink to={'/Service#Service-business'}>
                      <RectButton>
                        <div>{t('main.learnMore')}</div>
                      </RectButton>
                    </HashLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="container Idx-to-animate"
        >
          <div className="Home-our-services">
            <h1 style={{ margin: '0px', fontWeight: '900' }}>
              {t('main.our')}
              <span style={{ color: '#DDB989' }}> {t('main.services')}</span>
            </h1>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 Home-service-item">
              <div
                className="Home-service-item-child"
                style={{
                  backgroundImage: `url(${Airplane})`,
                }}
              >
                <div className="Service-overlay-black">
                  <div className="Service-title">
                    <h4 style={{ margin: '0px' }}>{t('main.airport')}</h4>
                  </div>
                  <div className="Service-info">{t('main.airportDesc')}</div>
                  <div className="Service-btn">
                    <p>
                      <HashLink to={'/Service#Service-airport'}>
                        <RectButton>
                          <div>{t('main.learnMore')}</div>
                        </RectButton>
                      </HashLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 Home-service-item">
              <div
                className="Home-service-item-child"
                style={{
                  backgroundImage: `url(${ManInSuit})`,
                }}
              >
                <div className="Service-overlay-gold">
                  <div className="Service-title">
                    <h4 style={{ margin: '0px' }}>{t('main.business')}</h4>
                  </div>
                  <div className="Service-info">{t('main.businessDesc')}</div>
                  <div className="Service-btn">
                    <p>
                      <HashLink to={'/Service#Service-business'}>
                        <RectButton variant="secondary">
                          <div>{t('main.learnMore')}</div>
                        </RectButton>
                      </HashLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 Home-service-item">
              <div
                className="Home-service-item-child"
                style={{
                  backgroundImage: `url(${WeddingRings})`,
                }}
              >
                <div className="Service-overlay-black">
                  <div className="Service-title">
                    <h4 style={{ margin: '0px' }}>{t('main.wedding')}</h4>
                  </div>
                  <div className="Service-info">{t('main.weddingDesc')}</div>
                  <div className="Service-btn">
                    <p>
                      <HashLink to={'/Service#Service-marriage'}>
                        <RectButton>
                          <div>{t('main.learnMore')}</div>
                        </RectButton>
                      </HashLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 Home-service-item">
              <div
                className="Home-service-item-child"
                style={{ backgroundImage: `url(${Vip})` }}
              >
                <div className="Service-overlay-gold">
                  <div className="Service-title">
                    <h4 style={{ margin: '0px' }}>{t('main.vip')}</h4>
                  </div>
                  <div className="Service-info">{t('main.vipDesc')}</div>
                  <div className="Service-btn">
                    <p>
                      <HashLink to={'/Service#Service-vip'}>
                        <RectButton variant="secondary">
                          <div>{t('main.learnMore')}</div>
                        </RectButton>
                      </HashLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 Home-service-item">
              <div
                className="Home-service-item-child"
                style={{
                  backgroundImage: `url(${BirthdayBalloons})`,
                }}
              >
                <div className="Service-overlay-black">
                  <div className="Service-title">
                    <h4 style={{ margin: '0px' }}>{t('main.birthday')}</h4>
                  </div>
                  <div className="Service-info">{t('main.birthdayDesc')}</div>
                  <div className="Service-btn">
                    <p>
                      <HashLink to={'/Service#Service-birthday'}>
                        <RectButton>
                          <div>{t('main.learnMore')}</div>
                        </RectButton>
                      </HashLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 Home-service-item">
              <div
                className="Home-service-item-child"
                style={{
                  backgroundImage: `url(${NeonQuestionmark})`,
                }}
              >
                <div className="Service-overlay-gold">
                  <div className="Service-title">
                    <h4 style={{ margin: '0px' }}>{t('main.special')}</h4>
                  </div>
                  <div className="Service-info">{t('main.specialDesc')}</div>
                  <div className="Service-btn">
                    <p>
                      <HashLink to={'/Service#Service-birthday'}>
                        <RectButton variant="secondary">
                          <div>{t('main.learnMore')}</div>
                        </RectButton>
                      </HashLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={(element) => elementsToAnimate.current.push(element)}
          className="container Idx-to-animate"
          style={{ textAlign: 'center' }}
        >
          <h1
            style={{
              margin: '0px',
              fontWeight: '900',
              padding: '20px 0px 30px 0px',
            }}
          >
            {t('main.checkOut')}
            <span style={{ color: '#DDB989' }}> {t('main.socials')}</span>
          </h1>
          <div className="Home-social-icons">
            <a href="https://www.instagram.com/platinum_chauffeurmtl/">
              <SquareButton>
                <i className="fa-brands fa-instagram Home-foot-icon"></i>
              </SquareButton>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100085095223427">
              <SquareButton>
                <i className="fa-brands fa-facebook Home-foot-icon"></i>
              </SquareButton>
            </a>
            <a href="https://www.tiktok.com/@platinumchauffeur">
              <SquareButton>
                <i className="fa-brands fa-tiktok Home-foot-icon"></i>
              </SquareButton>
            </a>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isViewModalOpen}
        onClose={handleCloseViewModal}
        title={t('main.checkRates')}
        shouldShowSecondBtn={false}
        width="100%"
      >
        <CheckRate />
      </CustomModal>
    </>
  );
};

export default Main;

import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

export function CustomDateTimePicker({
  id = 'defaults',
  label = 'Date Picker',
  value,
  onValueChange = () => {},
  defaultValue = '',
  readOnly = false,
  disabled = false,
  views = ['year', 'month', 'day', 'hours', 'minutes'],
  error = false,
  helperText = ' ',
}) {
  const today = dayjs();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl id={id} error sx={{ width: '100%' }}>
        <DateTimePicker
          label={label}
          value={value ? dayjs(value) : null}
          defaultValue={defaultValue ? dayjs(defaultValue) : null}
          onChange={onValueChange}
          readOnly={readOnly}
          disabled={disabled}
          views={views}
          format="MM/DD/YYYY HH:mm"
          minDate={today}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              background: '#FFFFFF',
              '& fieldset': {
                borderColor: error ? '#F44336' : '#D3D3D3',
              },
              '&:hover fieldset': {
                borderColor: '#2B2B2B',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#2B2B2B',
              },
              svg: {
                color: error ? '#F44336' : '#000000',
              },
            },
            '& label': {
              color: error ? '#F44336' : '#000000',
            },
            '& label.Mui-focused': {
              color: '#000000',
            },
          }}
        />
        <FormHelperText>{error ? helperText : ' '}</FormHelperText>
      </FormControl>
    </LocalizationProvider>
  );
}

export default CustomDateTimePicker;

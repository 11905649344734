import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function CustomSelectField({
  id = 'default',
  label = '',
  options = [], // options must be of format [{key: 'None', value: ''}, {key: '', value: ''} ...]
  error = false,
  helperText = ' ',
  onValueChange = () => {},
  readOnly = false,
  value = '',
  required = false,
}) {
  const handleChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <FormControl
      sx={{
        width: '100%',
        paddingBottom: '25px',
        '& .MuiOutlinedInput-root': {
          background: '#FFFFFF',
          '& fieldset': {
            borderColor: '#D3D3D3',
          },
          '&:hover fieldset': {
            borderColor: '#2B2B2B',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#2B2B2B',
          },
          svg: {
            color: '#000000',
          },
        },
        '& label': {
          color: '#000000',
        },
        '& label.Mui-focused': {
          color: '#2B2B2B',
        },
      }}
      error={error}
    >
      <InputLabel id={label + '_option'}>{label}</InputLabel>
      <Select
        labelId={label + '_option'}
        value={value}
        label={label}
        onChange={handleChange}
        //renderValue={(value) => {}} For customized values
        inputProps={{
          readOnly,
        }}
        required={required}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.key}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error ? helperText : ''}</FormHelperText>
    </FormControl>
  );
}

export default CustomSelectField;
